























































































import Vue from 'vue';
import { Component } from 'vue-property-decorator';

import Popover from '@/components/Popover.vue';
import { ActionButton, ActionButtonList } from '@/components/actionbuttons';
import Quill from '@/components/Quill.vue';
import { EmailText } from '@/models';
import { EmailTextService } from '@/lib/services';
import { unwrapError } from '@/lib/helpers';

@Component({
  components: {
    Popover,
    ActionButton,
    ActionButtonList,
    Quill,
  },
})
export default class EmailMessageEditor extends Vue {
  loading: boolean = true;
  item: EmailText = new EmailText();
  editItem: EmailText = new EmailText();
  error: string = '';
  editingTitle: boolean = false;
  newText: boolean = false;

  async mounted(): Promise<void> {
    try {
      const id = this.$route.params['messageId'];
      if (id === 'new') {
        this.editingTitle = true;
        this.newText = true;
      } else if (+id > 0) {
        this.item = await EmailTextService.get(+id);
      } else {
        this.error = 'Invalid parameter given';
        return;
      }

      Object.assign(this.editItem, this.item);
      this.loading = false;
    } catch (e) {
      this.$toaster.error('Could not load email', unwrapError(e));
    }
  }

  async saveBody(): Promise<void> {
    try {
      if (this.newText) {
        this.item = await EmailTextService.create(this.editItem);
        this.editingTitle = false;
        this.newText = false;
      } else {
        this.item.text = this.editItem.text;
        this.item.subject = this.editItem.subject;
        this.item = await EmailTextService.update(this.item);
      }
      this.$toaster.success('Email text saved');
    } catch (e) {
      this.$toaster.error('Email editing failed', unwrapError(e));
    }
  }

  editTitle(): void {
    this.editItem.name = this.item.name;
    this.editingTitle = true;
  }

  async saveTitle(): Promise<void> {
    try {
      this.item.name = this.editItem.name;
      this.item = await EmailTextService.update(this.item);
      this.editingTitle = false;
      this.$toaster.success('Title edited');
    } catch (e) {
      this.$toaster.error('Title editing failed', unwrapError(e));
    }
  }

  async deleteText(): Promise<void> {
    try {
      await EmailTextService.delete(this.item.id);
      this.$toaster.success('Deleted');
      this.close();
    } catch (e) {
      this.$toaster.error('Error deleting', unwrapError(e));
    }
  }

  close(): void {
    this.$router.push({ name: 'settings-email', query: { step: '2' } });
  }
}
